import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import StoryArcVisualization from './StoryArcVisualization';
import HeaderOptions from './HeaderOptions';
import './StoryArcList.css'; 

const apiUrl = process.env.REACT_APP_API_URL;

const StoryArcList = () => {
  const [storyArcs, setStoryArcs] = useState([]);
  const [allIssues, setAllIssues] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [progress, setProgress] = useState({ current: 0, total: 0 });
  const [hideSingleVolume, setHideSingleVolume] = useState(true);
  const [dateRange, setDateRange] = useState({ startDate: '2020-01-01', endDate: '' });

  const fetchStoryArcWithIssues = useCallback(async (arc) => {
    try {
      const issuesResponse = await axios.get(`${apiUrl}/story-arcs/${arc.id}/issues`);
      const issues = issuesResponse.data.map(issue => ({
        ...issue,
        story_arc_id: arc.id
      }));
      return { arc, issues };
    } catch (error) {
      console.error(`Error fetching issues for story arc ${arc.id}:`, error);
      return { arc, issues: [] };
    }
  }, []);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      // Fetch all story arcs first
      const arcsResponse = await axios.get(`${apiUrl}/story-arcs`, {
        params: { 
          hide_single_volume: hideSingleVolume,
          start_date: dateRange.startDate,
          end_date: dateRange.endDate
        }
      });
      const allArcs = arcsResponse.data;
      setProgress({ current: 0, total: allArcs.length });

      const newStoryArcs = [];
      const newAllIssues = [];

      // Fetch story arcs and their issues one by one
      for (let i = 0; i < allArcs.length; i++) {
        const { arc, issues } = await fetchStoryArcWithIssues(allArcs[i]);
        
        newStoryArcs.push(arc);
        newAllIssues.push(...issues);
        setProgress(prev => ({ ...prev, current: i + 1 }));
      }

      setStoryArcs(newStoryArcs);
      setAllIssues(newAllIssues);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError(error.message);
      setLoading(false);
    }
  }, [fetchStoryArcWithIssues, hideSingleVolume, dateRange]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleHideSingleVolumeChange = (newValue) => {
    setHideSingleVolume(newValue);
  };

  const handleDateRangeChange = (newDateRange) => {
    setDateRange(newDateRange);
  };

  if (error) {
    return <div className="error-message">Error: {error}</div>;
  }

  return (
    <div className="story-arc-list-container">
      <h1 className="story-arc-list-title">Marvel Story Arc Visualization</h1>
      <HeaderOptions 
        onHideSingleVolumeChange={handleHideSingleVolumeChange}
        onDateRangeChange={handleDateRangeChange}
      />
      {loading ? (
        <div className="loading-container">
          <div className="spinner"></div>
          <p className="loading-text">
            Loading data... Progress: {progress.current} / {progress.total} story arcs
          </p>
        </div>
      ) : storyArcs.length > 0 && allIssues.length > 0 ? (
        <div className="visualization-container">
          <StoryArcVisualization storyArcs={storyArcs} issues={allIssues} />
        </div>
      ) : (
        <p className="no-data-message">No data available</p>
      )}
    </div>
  );
};

export default StoryArcList;