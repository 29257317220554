import React, { useEffect, useRef, useState, useMemo } from 'react';
import * as d3 from 'd3';
import './StoryArcVisualization.css';

const ROW_HEIGHT = 15;
const STICKY_AXIS_HEIGHT = 50;
const MIN_WIDTH = 1200; // Minimum width for the visualization
const COLORS = [
  '#FF6B6B', '#4ECDC4', '#45B7D1', '#FFA07A', '#98D8C8',
  '#F06292', '#AED581', '#FFD54F', '#4DB6AC', '#7986CB'
];

const StoryArcVisualization = ({ storyArcs, issues }) => {
  const containerRef = useRef();
  const svgRef = useRef();
  const stickyAxisRef = useRef();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  // Sort story arcs based on their earliest issue date
  const sortedStoryArcs = useMemo(() => {
    const arcEarliestDates = new Map();

    // Find the earliest date for each story arc
    issues.forEach(issue => {
      const date = new Date(issue.publish_date);
      if (!arcEarliestDates.has(issue.story_arc_id) || date < arcEarliestDates.get(issue.story_arc_id)) {
        arcEarliestDates.set(issue.story_arc_id, date);
      }
    });

    // Sort the story arcs based on their earliest issue date
    return [...storyArcs].sort((a, b) => {
      const dateA = arcEarliestDates.get(a.id) || new Date(0);
      const dateB = arcEarliestDates.get(b.id) || new Date(0);
      return dateA - dateB;
    });
  }, [storyArcs, issues]);

  useEffect(() => {
    const updateDimensions = () => {
      const containerWidth = containerRef.current.offsetWidth;
      const containerHeight = containerRef.current.offsetHeight - 10;
      setDimensions({
        width: Math.max(containerWidth, MIN_WIDTH),
        height: Math.max(containerHeight, sortedStoryArcs.length * ROW_HEIGHT + 100)
      });
    };

    window.addEventListener('resize', updateDimensions);
    updateDimensions();

    return () => window.removeEventListener('resize', updateDimensions);
  }, [sortedStoryArcs.length]);

  useEffect(() => {
    if (!sortedStoryArcs.length || !issues.length || !dimensions.width || !dimensions.height) return;

    const margin = { top: 50, right: 50, bottom: STICKY_AXIS_HEIGHT, left: 300 };
    const width = dimensions.width - margin.left - margin.right;
    const height = dimensions.height - margin.top - margin.bottom;

    const svg = d3.select(svgRef.current);
    const stickyAxisSvg = d3.select(stickyAxisRef.current);
    svg.selectAll("*").remove();
    stickyAxisSvg.selectAll("*").remove();

    const chart = svg
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    const xScale = d3.scaleTime()
      .domain(d3.extent(issues, d => new Date(d.publish_date)))
      .range([0, width]);

    const yScale = d3.scalePoint()
      .domain(sortedStoryArcs.map(d => d.name))
      .range([0, height])
      .padding(0.5);

    const xAxis = d3.axisBottom(xScale);

    const totalHeight = height;
    const rowHeight = totalHeight / sortedStoryArcs.length;

    // Add alternating background for rows
    chart.selectAll('.row-background')
      .data(sortedStoryArcs)
      .enter()
      .append('rect')
      .attr('class', 'row-background')
      .attr('x', 0)
      .attr('y', (d, i) => i * rowHeight)
      .attr('width', width)
      .attr('height', rowHeight)
      .attr('fill', (d, i) => i % 2 === 0 ? 'rgba(255, 255, 255, 0.05)' : 'rgba(255, 255, 255, 0.02)');

    // Add vertical lines for year boundaries
    const yearRange = d3.timeYear.range(
      d3.timeYear.floor(xScale.domain()[0]),
      d3.timeYear.ceil(xScale.domain()[1])
    );

    chart.selectAll('.year-line')
      .data(yearRange)
      .enter()
      .append('line')
      .attr('class', 'year-line')
      .attr('x1', d => xScale(d))
      .attr('x2', d => xScale(d))
      .attr('y1', 0)
      .attr('y2', height)
      .attr('stroke', 'rgba(255, 255, 255, 0.1)')
      .attr('stroke-width', 1);

    chart.append('g')
      .attr('transform', `translate(0,${height})`)
      .attr('class', 'axis')
      .call(xAxis);

    // y-axis rendering with clickable links
    const yAxis = chart.append('g')
      .attr('class', 'y-axis')
      .call(d3.axisLeft(yScale).tickSize(5));

    yAxis.selectAll('.tick')
      .each(function(d) {
        const tick = d3.select(this);
        const text = tick.select('text');
        text.remove();
        
        const arcData = sortedStoryArcs.find(arc => arc.name === d);

        const foreignObject = tick.append('foreignObject')
          .attr('x', -margin.left)
          .attr('y', -10)
          .attr('width', margin.left)
          .attr('height', 20);

        const link = foreignObject.append('xhtml:a')
          .attr('href', arcData.cv_link)
          .attr('target', '_blank')
          .attr('rel', 'noopener noreferrer')
          .style('text-decoration', 'none')
          .style('color', 'inherit')
          .style('display', 'block')
          .style('width', '100%')
          .style('height', '100%');

        link.append('xhtml:div')
          .style('display', 'flex')
          .style('align-items', 'center')
          .style('justify-content', 'flex-end')
          .style('width', '100%')
          .style('height', '100%')
          .style('padding-right', '10px')
          .style('box-sizing', 'border-box')
          .append('xhtml:span')
          .style('white-space', 'nowrap')
          .style('overflow', 'hidden')
          .style('text-overflow', 'ellipsis')
          .text(d);
      });

    yAxis.select('.domain').remove();

    stickyAxisSvg
      .append('g')
      .attr('class', 'axis')
      .attr('transform', `translate(${margin.left},0)`)
      .call(xAxis);

    // Group issues by story arc and month
    const groupedIssues = d3.group(issues, 
      d => d.story_arc_id,
      d => d3.timeMonth(new Date(d.publish_date))
    );

    // Find the maximum number of issues per dot
    const maxIssuesPerDot = d3.max(Array.from(groupedIssues.values(), arcGroup => 
      d3.max(Array.from(arcGroup.values(), monthGroup => monthGroup.length))
    ));

    // Create an opacity scale
    const opacityScale = d3.scaleLinear()
      .domain([1, maxIssuesPerDot])
      .range([0.5, 1]);  // Adjust this range to your preference

    // Render dots
    Array.from(groupedIssues).forEach(([arcId, arcGroup]) => {
      Array.from(arcGroup).forEach(([month, issues]) => {
        const arcName = sortedStoryArcs.find(arc => arc.id === Number(arcId))?.name;
        chart.append('circle')
          .attr('class', 'dot')
          .attr('cx', xScale(month))
          .attr('cy', yScale(arcName))
          .attr('r', 4)
          .attr('fill', COLORS[Number(arcId) % COLORS.length])
          .attr('opacity', opacityScale(issues.length))
          .on('mouseover', function(event, d) {
            d3.select(this)
              .transition()
              .duration(200)
              .attr('r', 6);
            
            const formatDate = d3.timeFormat("%Y-%m");
            const tooltipText = `${arcName}: ${issues.length} issue(s) in ${formatDate(month)}`;
            
            chart.append('text')
              .attr('class', 'tooltip')
              .attr('x', xScale(month))
              .attr('y', yScale(arcName) - 10)
              .text(tooltipText)
              .attr('text-anchor', 'middle')
              .attr('fill', 'white')
              .attr('font-size', '12px')
              .attr('font-weight', 'bold')
              .attr('background', 'rgba(0, 0, 0, 0.7)')
              .attr('padding', '4px');

            // Add a background rectangle for better readability
            const textNode = chart.select('.tooltip').node();
            const textBBox = textNode.getBBox();
            
            chart.insert('rect', '.tooltip')
              .attr('class', 'tooltip-bg')
              .attr('x', textBBox.x - 5)
              .attr('y', textBBox.y - 2)
              .attr('width', textBBox.width + 10)
              .attr('height', textBBox.height + 4)
              .attr('fill', 'rgba(0, 0, 0, 0.7)')
              .attr('rx', 3)
              .attr('ry', 3);
          })
          .on('mouseout', function() {
            d3.select(this)
              .transition()
              .duration(200)
              .attr('r', 4);
            
            chart.selectAll('.tooltip, .tooltip-bg').remove();
          });
      });
    });

    // Synchronize scrolling
    const container = d3.select(svgRef.current.parentNode);
    container.on('scroll', () => {
      const scrollLeft = container.node().scrollLeft;
      stickyAxisSvg.style('transform', `translateX(-${scrollLeft}px)`);
    });

  }, [sortedStoryArcs, issues, dimensions]);

  return (
    <div ref={containerRef} className="story-arc-visualization">
      <div className="visualization-scrollable-container">
        <svg ref={svgRef} className="story-arc-svg" width={dimensions.width} height={dimensions.height}></svg>
      </div>
      <div className="sticky-axis-container">
        <svg 
          ref={stickyAxisRef} 
          className="sticky-axis-svg"
          width={dimensions.width}
          height={STICKY_AXIS_HEIGHT}
        ></svg>
      </div>
    </div>
  );
};

export default StoryArcVisualization;