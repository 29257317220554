import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="app-footer">
      <p>
        Data provided by <a href="https://comicvine.gamespot.com/" target="_blank" rel="noopener noreferrer">ComicVine</a> | 
        <a href="https://buymeacoffee.com/controversy187" target="_blank" rel="noopener noreferrer">☕ Buy Me a Coffee (or offset my server costs)</a>
      </p>
    </footer>
  );
};

export default Footer;
