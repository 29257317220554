import React, { useState } from 'react';
import './common.css';
import './HeaderOptions.css';

const HeaderOptions = ({ onHideSingleVolumeChange, onDateRangeChange }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [hideSingleVolume, setHideSingleVolume] = useState(true);
  const [startDate, setStartDate] = useState('2020-01-01');
  const [endDate, setEndDate] = useState('');

  const handleCheckboxChange = (event) => {
    const newValue = event.target.checked;
    setHideSingleVolume(newValue);
    onHideSingleVolumeChange(newValue);
  };

  const handleDateChange = (event) => {
    const { name, value } = event.target;
    if (name === 'startDate') {
      setStartDate(value);
    } else if (name === 'endDate') {
      setEndDate(value);
    }
    onDateRangeChange({ startDate: name === 'startDate' ? value : startDate, endDate: name === 'endDate' ? value : endDate });
  };

  return (
    <div className="container header-options">
      <div className={`options-container ${isExpanded ? 'expanded' : ''}`}>
        <div className="checkbox-container">
          <label className="checkbox-label">
            <input
              type="checkbox"
              checked={hideSingleVolume}
              onChange={handleCheckboxChange}
            />
            <span className="checkbox-text">Hide Single Volume Arcs</span>
          </label>
        </div>
        <div className="date-container">
          <div className="date-input">
            <label htmlFor="startDate" className="label">Start Date:</label>
            <input
              type="date"
              id="startDate"
              name="startDate"
              value={startDate}
              onChange={handleDateChange}
              className="input"
            />
          </div>
          <div className="date-input">
            <label htmlFor="endDate" className="label">End Date:</label>
            <input
              type="date"
              id="endDate"
              name="endDate"
              value={endDate}
              onChange={handleDateChange}
              className="input"
            />
          </div>
        </div>
      </div>
      <button 
        className={`toggle-button ${isExpanded ? 'expanded' : ''}`}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <span className="toggle-text">{isExpanded ? 'Hide' : 'Show'} Options</span>
        <span className={`toggle-arrow ${isExpanded ? 'expanded' : ''}`}>︽</span>
      </button>
    </div>
  );
};

export default HeaderOptions;